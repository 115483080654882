var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accentColor":"primary"}},[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Instansi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Instansi")]),_c('v-select',{class:[
                          { 'is-valid': !errors[0] },
                          { 'is-invalid': errors[0] } ],attrs:{"item-text":"nama_instansi","item-value":"id_instansi","disabled":"","options":_vm.optionsInstansi,"label":"nama_instansi","filterable":false},on:{"search":_vm.onSearch},model:{value:(_vm.defaultSelected),callback:function ($$v) {_vm.defaultSelected=$$v},expression:"defaultSelected"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"OPD"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("OPD")]),_c('v-select',{class:[
                          { 'is-valid': !errors[0] },
                          { 'is-invalid': errors[0] } ],attrs:{"options":_vm.optionsOPD,"label":"nama_opd","reduce":function (opd) { return opd.id_opd; },"filterable":true},on:{"search":_vm.onSearchOPD},model:{value:(_vm.form.id_opd),callback:function ($$v) {_vm.$set(_vm.form, "id_opd", $$v)},expression:"form.id_opd"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Unit Kerja"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Unit Kerja")]),_c('v-select',{class:[
                          { 'is-valid': !errors[0] },
                          { 'is-invalid': errors[0] } ],attrs:{"options":_vm.optionsUnitKerja,"label":"nama_unit_kerja","reduce":function (unit_kerja) { return unit_kerja.id_unit_kerja; },"filterable":true},on:{"search":_vm.onSearchUnitKerja},model:{value:(_vm.form.id_unit_kerja),callback:function ($$v) {_vm.$set(_vm.form, "id_unit_kerja", $$v)},expression:"form.id_unit_kerja"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Jabatan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Jabatan")]),_c('v-select',{class:[
                          { 'is-valid': !errors[0] },
                          { 'is-invalid': errors[0] } ],attrs:{"options":_vm.optionsJabatanFungsional,"label":"nama_jabatan","reduce":function (jabatan) { return jabatan; },"filterable":false},on:{"search":_vm.onSearchJabatan,"input":function($event){return _vm.onChangesJabatan($event)}},model:{value:(_vm.Selectedjabatan),callback:function ($$v) {_vm.Selectedjabatan=$$v},expression:"Selectedjabatan"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('CCol',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"required|numeric","name":"Kelas Jabatan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Kelas Jabatan","placeholder":"Kelas Jabatan","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.kelas_jabatan),callback:function ($$v) {_vm.$set(_vm.form, "kelas_jabatan", $$v)},expression:"form.kelas_jabatan"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"required|numeric","name":"ABK"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Analisa Beban Kerja","placeholder":"Analisa Beban kerja","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.abk),callback:function ($$v) {_vm.$set(_vm.form, "abk", $$v)},expression:"form.abk"}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"6"}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Simpan")])],1),_c('CCol',{staticClass:"text-right",attrs:{"col":"6"}})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }